export default defineNuxtRouteMiddleware(async (to) => {
  const settings = useSettings();

  const establishments = settings.value.establishments;

  if (establishments.length === 0) {
    return navigateTo(
      {
        name: "profile",
      },
      { replace: true },
    );
  }

  if (to.params.establishment_uuid) {
    const establishment = establishments.find(
      (establishment) => establishment.uuid === to.params.establishment_uuid,
    );

    if (!establishment) {
      return navigateTo(
        {
          name: "profile",
        },
        { replace: true },
      );
    }
  }
});
